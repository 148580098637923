import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IProduct} from 'app/blocks/model/product.model';
import {IVehicleType} from 'app/blocks/model/vehicle-type.model';
import {IVehicle} from 'app/blocks/model/vehicle.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';

export interface IProductImeiTrack extends IBaseEntity {
    id?: number;
    imeiNumber?: string;
    product?: IProduct;
    warehouse?: IWarehouse;
    vehicle?: IVehicle;
    deliveryPoint?: IDeliveryPoint;
    status?: string;
    updatedOn?: any;
}
export class ProductImeiTrack extends BaseEntity implements IProductImeiTrack {
    public id?: number;
    public imeiNumber?: string;
    public product?: IProduct;
    public warehouse?: IWarehouse;
    public vehicle?: IVehicle;
    public deliveryPoint?: IDeliveryPoint;
    public status?: string;
    public updatedOn?: any;

    constructor(productImeiTrack?) {
        super();
        if (productImeiTrack) {
            this.id = productImeiTrack.id;
            this.imeiNumber = productImeiTrack.imeiNumber;
            this.product = productImeiTrack.product;
            this.warehouse = productImeiTrack.warehouse;
            this.vehicle = productImeiTrack.vehicle;
            this.deliveryPoint = productImeiTrack.deliveryPoint;
            this.status = productImeiTrack.status;
            this.updatedOn = productImeiTrack.updatedOn;
        }
    }
    get discriminator(): any {
        return this.imeiNumber;
    }
    clone(): ProductImeiTrack {
        return new ProductImeiTrack(this);
    }
}
